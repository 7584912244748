import RootModal from 'common/components/Modals/RootModal';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/scss/tabler.scss';
import ErrorBoundary from 'common/components/HOC/ErrorBoundary';
import MainRoutes from 'common/components/Router/MainRoutes';
import configureStore from './store';
import UserGuard from 'common/components/Router/UserGuard';
import { ThemeProvider } from 'styled-components';
import { theme } from 'mss-component-library';

const store = configureStore();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <ErrorBoundary>
            <UserGuard>
              <MainRoutes />
            </UserGuard>
          </ErrorBoundary>
        </Router>
        <RootModal />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
