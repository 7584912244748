import { SearchResponse } from 'elasticsearch';
import { QueryOptions, useQuery } from 'react-query';

function useFetchElasticQuery<TParameters extends object, TResult>(
  fetch,
  queryKey: string,
  parameters?: TParameters,
  options?: QueryOptions<SearchResponse<TResult>>,
): [boolean, SearchResponse<TResult>, null | Error] {
  const { data, isLoading, error } = useQuery<
    SearchResponse<TResult>,
    TParameters
  >([queryKey, parameters], fetch, { refetchInterval: false, ...options });

  return [isLoading, data, error];
}

export default useFetchElasticQuery;
