import { Sliders, MapPin } from 'react-feather';
import { NavItem } from 'reactstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { selectMe } from 'settings/store/selectors';
import Divider from 'common/components/Divider';
import { useTranslation } from 'react-i18next';

const CenterNavbar = () => {
  const user: any = useSelector(selectMe);
  const [t] = useTranslation('common');

  return (
    <>
      <NavItem>
        <NavLink className="nav-link" to="/sites/map" exact>
          <MapPin className="nav-icon" size={14}
            data-ta="Sitemap" />
          {t('Sitemap')}
        </NavLink>
      </NavItem>
      {user.is_superuser && (
        <>
          <Divider vertical />
          <NavItem>
            <NavLink className="nav-link" to="/config">
              <Sliders className="nav-icon" size={14}
                data-ta="SitemapConfiguration" />
              {t('Configuration')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" to="/sites/manager">
              <FontAwesomeIcon
                icon={faLayerGroup}
                size={'sm'}
                style={{ marginRight: 4 }}
                data-ta="SiteManager"
              />
              {t('Site Manager')}

            </NavLink>
          </NavItem>
        </>
      )}
    </>
  );
};

export default CenterNavbar;
