import { fetchElasticQuery } from 'common/services/FetchElasticQuery';

export async function fetchUptimeData({ id, index }) {
  /**
   *  get uptime data
   */
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': id,
            },
          },
          {
            match: {
              'mss.collector_key': 'uptime',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-48h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      uptime_per_hour: {
        date_histogram: {
          field: '@timestamp',
          fixed_interval: '1h',
          missing: '0',
          min_doc_count: 0,
          extended_bounds: {
            min: 'now-48h',
            max: 'now',
          },
        },
        aggs: {
          status_uptime: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              script_fields: {
                up: {
                  script: {
                    source: `
                      def up = false;
                      if ((doc['snmp.uptime'].value/100)/60 > 60) {
                        up = true;
                      }else{
                        up = false;
                      }
                      return up;
                    `,
                  },
                },
              },
            },
          },
        },
      },
      latest_uptime_info: {
        filter: {
          range: {
            '@timestamp': {
              from: 'now-125m',
              to: 'now',
            },
          },
        },
        aggs: {
          last_document: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
              _source: {
                includes: ['@timestamp'],
              },
              script_fields: {
                uptime: {
                  script: "doc['snmp.uptime'].value/100",
                },
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchCpuMemoryLastValue({ id, index }) {
  /**
   *  Get last value for CPU and Memory load
   */
  const query = {
    size: 0,
    _source: {
      includes: ['@timestamp', 'snmp'],
    },
    sort: [
      {
        '@timestamp': {
          order: 'desc',
        },
      },
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': id,
            },
          },
          {
            match: {
              'mss.collector_key': 'cpu_memory',
            },
          },
        ],
      },
    },
    aggs: {
      latest_uptime_info: {
        top_hits: {
          sort: [
            {
              '@timestamp': {
                order: 'desc',
              },
            },
          ],
          size: 1,
          script_fields: {
            avg_cpu_load: {
              script: "doc['snmp.avg_cpu_load'].value",
            },
            avg_memory_load: {
              script: {
                source: `
                      float allocated = doc['snmp.avg_memory_allocated'].value;
                      float free = doc['snmp.avg_memory_free'].value;
                      return allocated / free * 100;
              `,
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchTempData({ id, index }) {
  /**
   *  get uptime data
   */
  const query = {
    size: 1,
    _source: {
      includes: ['@timestamp', 'snmp'],
    },
    sort: [
      {
        '@timestamp': {
          order: 'desc',
        },
      },
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': id,
            },
          },
          {
            match: {
              'mss.collector_key': 'temperature',
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}
