import Api from 'common/services/Api';

const baseUrl = '/sites/';
export function fetchSites() {
  const params = {
    params: {
      deleted: false,
    },
  };
  return Api.get(baseUrl, params);
}

export function updateSite(siteId: string, body: any) {
  return Api.put(`${baseUrl}${siteId}/`, body);
}

export function addSite(body) {
  return Api.post(baseUrl, body);
}

export function deleteSite(siteId: string) {
  return Api.delete(`${baseUrl}${siteId}/`);
}
