import { all, takeLatest, call, put } from 'redux-saga/effects';
import { fetchCenter } from '../services/CenterService';
import {
  fetchCenterCompleted,
  fetchCenterRequested,
  fetchCenterFailed,
} from './slice';

function* fetchCenterEffectSaga() {
  try {
    let { data } = yield call(fetchCenter);
    yield put(fetchCenterCompleted(data));
  } catch (e) {
    yield put(fetchCenterFailed());
  }
}

export function* fetchCenterWatcherSaga() {
  yield takeLatest(fetchCenterRequested.toString(), fetchCenterEffectSaga);
}

export default function* centerRootSaga() {
  yield all([fetchCenterWatcherSaga()]);
}
