import { all } from 'redux-saga/effects';
import computeAssetsRootSaga from '../computeAssets/store/sagas';
import fieldAssetsRootSaga from '../fieldAssets/store/sagas';
import iiotAssetsRootSaga from '../iiotAssets/store/sagas';
import settingsRootSaga from '../settings/store/sagas';
import sitesRootSaga from '../sites/store/sagas';
import plcDcsAssetsRootSaga from '../plcDcsAssets/store/sagas';
import networkAssetsRootSaga from '../networkAssets/store/sagas';
import configurationRootSaga from '../configuration/store/sagas';
import securityAppsRootSaga from 'securityApps/store/sagas';
import assetManagementAppsRootSaga from 'assetManagementApps/store/sagas';
import analyzerManagementAppsRootSaga from 'analyzerManagementApps/store/sagas';
import controlAppsRootSaga from 'controlApps/store/sagas';
import envAssetsRootSaga from '../envAssets/store/sagas';
import dashboardsRootSaga from '../dashboards/store/sagas';

export default function* rootSaga() {
  yield all([
    computeAssetsRootSaga(),
    sitesRootSaga(),
    fieldAssetsRootSaga(),
    plcDcsAssetsRootSaga(),
    networkAssetsRootSaga(),
    iiotAssetsRootSaga(),
    settingsRootSaga(),
    configurationRootSaga(),
    securityAppsRootSaga(),
    assetManagementAppsRootSaga(),
    analyzerManagementAppsRootSaga(),
    controlAppsRootSaga(),
    envAssetsRootSaga(),
    dashboardsRootSaga(),
  ]);
}
