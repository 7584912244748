import { selectAssetManagementApplicationsById } from 'assetManagementApps/store/selectors';
import { createSelector } from 'reselect';

const selectedFieldAssets = state => state.default.fieldAssets.fieldAssets;
const selectedFieldAsset = state => state.default.fieldAssets.fieldAsset;
const selectedTotal = state => state.default.fieldAssets.total;

export const selectFieldAssets = createSelector(
  [selectedFieldAssets],
  fieldAssets => fieldAssets,
);

export const selectFieldAsset = createSelector(
  [selectedFieldAsset],
  fieldAsset => fieldAsset,
);

export const selectFieldAssetsTotal = createSelector(
  [selectedTotal],
  total => total,
);

export const selectEnrichedFieldAssets = createSelector(
  selectFieldAssets,
  selectAssetManagementApplicationsById,
  (fieldAssets, assetManagementApps) => {
    const fieldAssetsWithPrmName = fieldAssets.map(fieldAsset => {
      const foundAssetApp = assetManagementApps[fieldAsset.prm];

      return {
        ...fieldAsset,
        prm_updated_name: foundAssetApp
          ? foundAssetApp.name
          : fieldAsset.prm_name,
      };
    });

    return fieldAssetsWithPrmName;
  },
);

export const selectEnrichedFieldAsset = createSelector(
  selectFieldAsset,
  selectAssetManagementApplicationsById,
  (fieldAsset, assetManagementApps) => {
    const foundFieldAsset = assetManagementApps[fieldAsset.prm];

    return {
      ...fieldAsset,
      prm_updated_name: foundFieldAsset
        ? foundFieldAsset.name
        : fieldAsset.prm_name,
    };
  },
);
