import React, { useMemo, useState, useEffect } from 'react';
import Logo from 'common/components/Logo';
import CustomLogo from 'common/components/CustomLogo';
import SiteSelector from 'sites/components/SiteSelector';
import { selectedCenter } from 'configuration/store/selectors';
import { Link, useHistory, useParams } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import User from 'common/components/Header/User';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex } from 'mss-component-library';
import { useSelector, useDispatch } from 'react-redux';
import { selectedSites } from 'sites/store/selectors';
import emptySite from 'sites/util/emptySite';
import { fetchCenterRequested } from 'configuration/store/slice';

const SubTitle = styled.span`
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
  margin-left: 0.75rem;
  font-size: 1.25rem;
  color: white;
`;

interface QuestionButtonProps {
  visible: boolean;
}

const QuestionButton = styled(QuestionCircleOutlined)<QuestionButtonProps>`
  font-size: 20px;
  margin-left: 20px;
  color: white;
  opacity: ${props => (props.visible ? 1 : 0)};
  cursor: ${props => (props.visible ? 'pointer' : 'inherit !important')};
  transition: opacity 0.13s ease-in-out;
`;

// TODO: Make an reusable header with left/center/right subcomponents instead of extra main header components
function Header({ onInfoClick, drawerHasContent }) {
  const history = useHistory();
  const [t] = useTranslation('header');
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const sites = useSelector(selectedSites);
  const selectedSite = useMemo(() => {
    const site = sites.find(x => x.id === siteId);
    if (!site) return emptySite;
    return site;
  }, [sites, siteId]);
  const center = useSelector(selectedCenter);
  const [centerLogo, setCenterLogo] = useState(center ? center.logo : '');
  const [siteLogo, setSiteLogo] = useState(
    selectedSite ? selectedSite.logo : '',
  );
  useEffect(() => {
    if (!center.id) dispatch(fetchCenterRequested());
    setCenterLogo(center ? center.logo : '');
  }, [dispatch, center.id, center.logo]);

  useEffect(() => {
    setSiteLogo(selectedSite ? selectedSite.logo : '');
  }, [selectedSite]);

  return (
    <div className="bg-primary">
      <header className="header container d-flex align-items-center">
        <Flex v="center" h="start">
          <Link
            to="/"
            className="brand text-inherit"
            style={{ padding: '12px 0 12px 12px' }}
          >
            <Logo />
          </Link>
          <SubTitle>{t('Center Portal')}</SubTitle>
          {siteLogo && <CustomLogo src={siteLogo} />}
          {!siteLogo && centerLogo && <CustomLogo src={centerLogo} />}
        </Flex>

        <div className="d-flex align-items-center">
          <div className="mr-3">
            <div style={{ width: '150px' }}>
              <SiteSelector history={history} />
            </div>
          </div>

          <Flex v="center" h="center">
            <User />
            <QuestionButton
              onClick={drawerHasContent ? onInfoClick : () => null}
              visible={drawerHasContent}
            />
          </Flex>
        </div>
      </header>
    </div>
  );
}

export default Header;
