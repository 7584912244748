import { createSlice, createAction } from '@reduxjs/toolkit';
import { arrayToObject } from 'common/utils';
import { QueryParams } from 'mss-component-library';

// List field asset
export const fetchFieldAssetsRequested = createAction<QueryParams>(
  'fieldAssets/fetchFieldAssetsRequested',
);
export const fetchFieldAssetsFailed = createAction(
  'fieldAssets/fetchFieldAssetsFailed',
);

// Single field asset
export const fetchFieldAssetRequested = createAction<{
  assetId: string;
}>('fieldAssets/fetchFieldAssetRequested');
export const fetchFieldAssetFailed = createAction(
  'fieldAssets/fetchFieldAssetFailed',
);

const modalsSlice = createSlice({
  name: 'fieldAssets',
  initialState: {
    fieldAssets: [],
    fieldAssetsById: {},
    fieldAsset: {},
    total: 0,
  },
  reducers: {
    fetchFieldAssetsCompleted(state, action) {
      state.fieldAssets = action.payload || [];
      state.fieldAssetsById = arrayToObject(state.fieldAssets, 'id');
      state.total = state.fieldAssets.length;
    },
    fetchFieldAssetCompleted(state, action) {
      state.fieldAsset = action.payload;
    },
  },
});

export const {
  fetchFieldAssetCompleted,
  fetchFieldAssetsCompleted,
} = modalsSlice.actions;

export default modalsSlice.reducer;
