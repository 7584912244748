import {
  CustomInput,
  ModalBody as OriginalBody,
  ModalFooter as OriginalFooter,
  ModalHeader as OriginalHeader,
} from 'reactstrap';
import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';

import { acceptTermsRequested } from 'settings/store/slice';
import { terms, acceptTerms } from 'settings/services/SettingsService';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Loading from 'common/components/Loading';
import StaticModal from 'common/components/Modals/StaticModal';
import ErrorBoundary from 'common/components/HOC/ErrorBoundary';
import KeycloakButton from 'common/components/KeycloakButton';

const text = {
  heading: 'Terms & Conditions',
  acceptText: "I've read the terms & conditions",
  acceptButton: 'Accept',
  declineButton: 'Decline',
};

interface ModalBodyContentProps {
  hasContent: boolean;
}

const ModalHeader = styled(OriginalHeader)`
  border: none !important;
  padding: 32px !important;
  padding-bottom: 24px !important;

  > h5 {
    font-size: 1.5rem !important;
    font-weight: 500;
  }
`;

const ModalBody = styled(OriginalBody)`
  padding: 0 32px !important;
`;

const BodyBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: min-height ease 0.2s;
  min-height: 100px;

  @media (min-width: 576px) {
    min-height: 150px;
  }

  ${(p: ModalBodyContentProps) =>
    p.hasContent
      ? css`
          min-height: 0px !important;
          align-items: flex-start;

          justify-content: flex-start;
        `
      : null}
`;

const ModalFooter = styled(OriginalFooter)`
  padding: 16px 32px !important;
  border: none !important;
`;

const Label = styled.span`
  font-weight: 600;
  cursor: pointer;
`;

const StyledSwitch = styled(CustomInput)`
  &:not(:first-child) {
    margin: 1rem 0 !important;
  }
  > * {
    cursor: pointer !important;
  }

  .custom-control-label {
    &::before {
      background: #d8d8d8;
      border: 1px solid #d8d8d8;
    }

    &::after {
      background: white !important;
    }
  }
`;

const TermsConditions = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const [readTerms, setReadTerms] = useState(false);
  const [termsContent, setTermsContent] = useState(null);

  useEffect(() => {
    async function fetchContent() {
      try {
        const { data } = await terms();
        if (data) {
          setTermsContent(data);
        }
      } catch (err) {
        throw new Error('Invalid terms response');
      }
    }
    fetchContent();
  }, []);

  const acceptTermsAndNavigateBack = () => {
    dispatch(acceptTermsRequested());
    history.push(url);
  };

  const declineTermsAndLogout = async () => {
    await acceptTerms(false);
    if (form.current) form.current.submit();
  };
  const form = useRef<HTMLFormElement>();

  return (
    <ErrorBoundary>
      <StaticModal>
        <ModalHeader>{text.heading}</ModalHeader>
        <ModalBody>
          <BodyBlock hasContent={Boolean(termsContent)}>
            {termsContent && (
              <>
                <span dangerouslySetInnerHTML={{ __html: termsContent }}></span>
                <StyledSwitch
                  id="agreeTermsSwitch"
                  type="switch"
                  value={readTerms}
                  onChange={() => setReadTerms(!readTerms)}
                  label={<Label>{text.acceptText}</Label>}
                />
              </>
            )}
            {!termsContent && <Loading />}
          </BodyBlock>
        </ModalBody>
        <ModalFooter>
          <form
            ref={form}
            action={`${process.env.REACT_APP_API_URL}/auth/logout/`}
            method="POST"
          >
            <KeycloakButton
              color="grey"
              disabled={!termsContent}
              onClick={declineTermsAndLogout}
            >
              {text.declineButton}
            </KeycloakButton>
          </form>
          <KeycloakButton
            disabled={!readTerms}
            color="primary"
            onClick={acceptTermsAndNavigateBack}
          >
            {text.acceptButton}
          </KeycloakButton>
        </ModalFooter>
      </StaticModal>
    </ErrorBoundary>
  );
};

export default TermsConditions;
