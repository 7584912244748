import Api from 'common/services/Api';
import { fetchElasticQuery } from 'common/services/FetchElasticQuery';

export async function fetchProjectInventory({
  assetId,
  index,
  search,
  filter,
}) {
  const query = {
    size: 0,
    sort: [
      {
        'domain.keyword': {
          order: 'asc',
        },
      },
      {
        'station.keyword': {
          order: 'asc',
        },
      },
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'project_inventory',
            },
          },
          {
            bool: {
              should: [],
            },
          },
        ],
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      'top-hits': {
        terms: {
          field: 'stn_name.keyword',
          size: 1000
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              _source: {
                includes: [
                  'domain',
                  'station',
                  'stn_code',
                  'stn_name',
                  'stn_type',
                  'another_stn_name',
                  'et_addr',
                  'et_name',
                  '@timestamp',
                  'mss',
                ],
              },
              size: 1,
            },
          },
        },
      },
    },
  };

  function boolShouldMatchPhrase(key, values) {
    const arr = values.map(x => ({
      match_phrase_prefix: {
        [key]: x,
      },
    }));

    return {
      bool: {
        should: arr,
      },
    };
  }

  if (filter) {
    const filters = Object.keys(filter);
    const activatedFilters = filters
      .filter(x => filter[x] != null)
      .map(x => boolShouldMatchPhrase(x, filter[x]));

    activatedFilters.forEach(x => query.query.bool.must.push(x));
  }

  function filterPrefix(key: string, value: string) {
    return {
      match_phrase_prefix: {
        [key]: value,
      },
    };
  }

  if (search && search.length > 0) {
    query.query.bool.filter = {
      bool: {
        must: [],
      },
    };

    const validSearches = search
      .filter(x => x.value != null && x.value.length > 0)
      .map(x => filterPrefix(x.key, x.value));

    validSearches.forEach(x => query.query.bool.filter.bool.must.push(x));
  }

  return fetchElasticQuery(index, query);
}

export async function fetchHistoricalMessages({
  assetId,
  index,
  pagination,
  search,
  filter,
  sort,
}) {
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'message',
        'message_number',
        'message_major_cat',
        'message_medium_cat',
        'message_small_cat',
        'source',
        'time_stamp',
        'mss',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'historical_messages',
            },
          },
        ],
      },
    },
  };

  function boolShouldMatchPhrase(key, values) {
    const arr = values.map(x => ({
      match_phrase: {
        [key]: x,
      },
    }));

    return {
      bool: {
        should: arr,
      },
    };
  }

  function filterPrefix(key: string, value: string) {
    return {
      match_phrase_prefix: {
        [key]: value,
      },
    };
  }

  if (filter) {
    const filters = Object.keys(filter);
    const activatedFilters = filters
      .filter(x => filter[x] != null)
      .map(x => boolShouldMatchPhrase(x, filter[x]));
    activatedFilters.forEach(x => query.query.bool.must.push(x));
  }

  if (search) {
    query.query.bool.filter = {
      bool: {
        must: [],
      },
    };

    const validSearches = search
      .filter(x => x.value != null && x.value.length > 0)
      .map(x => filterPrefix(x.key, x.value));

    validSearches.forEach(x => query.query.bool.filter.bool.must.push(x));
  }

  return fetchElasticQuery(index, query);
}

export async function fetchHeartbeatChartData({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'ping',
            },
          },
          {
            range: {
              '@timestamp': {
                gte: 'now-30d',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
    aggs: {
      state_over_time: {
        date_histogram: {
          field: '@timestamp',
          calendar_interval: '1d',
        },
        aggs: {
          state_aggregation: {
            terms: {
              field: 'state.keyword',
            },
          },
          up_percentage: {
            bucket_script: {
              buckets_path: {
                up: "state_aggregation['up']>_count",
                down: "state_aggregation['down']>_count",
              },
              gap_policy: 'insert_zeros',
              script: {
                source:
                  'if (params.down == null && params.up == null) {-1} else if (params.down == null) {1} else if (params.up == null) {0} else {params.up / (params.up + params.down)}',
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchControlApplication(assetId: string): Promise<any> {
  return Api.get(`/assets/${assetId}/`);
}

export async function fetchControlApplications(parameters: {
  site: string;
}): Promise<any> {
  parameters['type'] = 'control';
  parameters['deleted'] = 'false';
  return Api.get(`/assets/`, { params: parameters });
}
