import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchFieldAssets,
  fetchFieldAsset,
} from 'fieldAssets/services/FieldAssetApiService';
import {
  fetchFieldAssetsCompleted,
  fetchFieldAssetsFailed,
  fetchFieldAssetCompleted,
  fetchFieldAssetFailed,
  fetchFieldAssetRequested,
  fetchFieldAssetsRequested,
} from './slice';

// Fetch field assets list
function* fetchFieldAssetsEffectSaga(action) {
  try {
    let { data } = yield call(fetchFieldAssets, action.payload);
    yield put(fetchFieldAssetsCompleted(data));
  } catch (e) {
    yield put(fetchFieldAssetsFailed());
  }
}

export function* fetchFieldAssetsWatcherSaga() {
  yield takeLatest(
    fetchFieldAssetsRequested.toString(),
    fetchFieldAssetsEffectSaga,
  );
}

// Fetch field asset singular
function* fetchFieldAssetEffectSaga(action) {
  try {
    const { data } = yield call(fetchFieldAsset, action.payload);
    const fieldAsset = data;
    yield put(fetchFieldAssetCompleted(fieldAsset));
  } catch (e) {
    yield put(fetchFieldAssetFailed());
  }
}

export function* fetchFieldAssetWatcherSaga() {
  yield takeLatest(
    fetchFieldAssetRequested.toString(),
    fetchFieldAssetEffectSaga,
  );
}

export default function* fieldAssetsRootSaga() {
  yield all([fetchFieldAssetsWatcherSaga(), fetchFieldAssetWatcherSaga()]);
}
