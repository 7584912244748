import React from 'react';
import {
  Segment,
  AssetRemoteAccessProfile,
  Flex,
  openNotificationWithIcon,
  Button,
  SubmitButton,
} from 'mss-component-library';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Input, Form } from 'formik-antd';
import { Formik } from 'formik';
import styled from 'styled-components';
import { notification } from 'antd';
import Api from './remoteService';
import { useParams } from 'react-router-dom';

const requiredInputs: (t: TFunction) => any = (t: TFunction) => ({
  username: { key: 'input', value: t('Username') },
  domain: { key: 'input', value: t('Domain') },
  password: { key: 'password', value: t('Password') },
  passphrase: { key: 'password', value: t('Passphrase') },
  private_key: { key: 'textarea', value: t('Private key') },
});

const Heading = styled.span`
  font-size: 14px;
  padding-left: 8px;
  font-weight: normal;
`;

const RemoteCreateConnection = ({
  profile,
  closeModal,
  requestId,
}: {
  profile: AssetRemoteAccessProfile;
  closeModal: () => void;
  requestId?: string;
}) => {
  const [t] = useTranslation(['remoteAccess', 'common']);
  const { siteId } = useParams<{ siteId: string }>();
  const initialValues: AssetRemoteAccessProfile & any = {
    ...profile,
    profile_id: profile.id,
    remote_request_id: requestId,
  };
  profile.required_input_fields.forEach(x => (initialValues[x] = ''));

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async values => {
        delete values.required_input_fields;
        delete values.start_remote_session;
        delete values.create_remote_request;
        if (requestId) {
          delete values.profile_id;
        } else {
          delete values.remote_request_id;
        }
        let result = await Api.session.start(values);

        if (result.status === 201) {
          const { data } = result;
          window.open(
            `/site/${siteId}/remote-access/client/${data.session_id}/`,
            '_blank',
          );
        } else {
          openNotificationWithIcon(
            notification.error,
            t('Session could not be started'),
            t(
              'Due to technical issues the remote sessions could not be started. Please try again later.',
            ),
          );
        }
        closeModal();
      }}
    >
      {formik => {
        const { values, errors } = formik;
        return (
          <Form>
            <Input type="hidden" name="id" value={values.id} />
            {profile.required_input_fields.length > 0 && (
              <Segment
                style={{ marginTop: 24 }}
                padding
                heading={
                  <Heading>
                    {t('Provide the required authentication information.')}
                  </Heading>
                }
              >
                {profile.required_input_fields.map(input => {
                  const requiredInput = requiredInputs(t)[input];
                  const inputProps = {
                    name: input,
                    value: values[input],
                    style: { width: '100%' },
                  };
                  return (
                    <Flex className="mt-1" key={input}>
                      <span style={{ minWidth: 100, marginLeft: 8 }}>
                        {requiredInput.value}
                      </span>
                      {requiredInput.key === 'input' && (
                        <Input {...inputProps} />
                      )}
                      {requiredInput.key === 'textarea' && (
                        <Input.TextArea {...inputProps} rows={4} />
                      )}
                      {requiredInput.key === 'password' && (
                        <Input.Password {...inputProps} />
                      )}
                    </Flex>
                  );
                })}
              </Segment>
            )}
            <Flex
              v="center"
              h="end"
              className={profile.required_input_fields.length > 0 ? 'pr-4' : ''}
            >
              <Button
                theming="center"
                variant="primary"
                ghost
                onClick={closeModal}
              >
                {t('Cancel')}
              </Button>
              <SubmitButton
                theming="center"
                disabled={Object.keys(errors).length > 0}
                className="ml-2"
              >
                {t('common:Connect')}
              </SubmitButton>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RemoteCreateConnection;
