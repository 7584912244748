import { createSelector } from 'reselect';
import { Me, Access } from 'mss-component-library';

const selectedNav = state => state.default.settings.nav;
const selectedMe = state => state.default.settings.me;
const chosenLanguages = state => state.default.settings.languages;
const chosenTimezones = state => state.default.settings.timezones;

export const selectNav = createSelector([selectedNav], (nav: Access[]) => nav);
export const selectMe = createSelector([selectedMe], (me: Me | null) => me);
export const languages = createSelector([chosenLanguages], x => x);
export const timezones = createSelector([chosenTimezones], x => x);
