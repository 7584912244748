import Api from '../../common/services/Api';
import { fetchElasticQuery } from '../../common/services/FetchElasticQuery';
import { parseDateRange } from '../../common/utils';

const initialSortActivity = {
  'created_date': 'desc',
};
export async function fetchActivityLogs({
  assetId,
  index,
  start,
  end,
  sort=initialSortActivity,
  pagination,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'created_date',
        'event_type',
        'event_group',
        'user_name',
        'event_message',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'activity_log',
            },
          }
        ],
        filter: [
          {
            range: {
              created_date: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ]
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAnalyzers({ 
  assetId,
  index,
}) {
  const query = {
    size: 10000,
    _source: {
      includes: [
        'name',
        'manufacturer',
        'type',
        'model',
        'service',
        'date_of_service',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'connected_analyzers',
            },
          }
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

const initialSortApplication = {
  'created_time': 'desc',
};
export async function fetchApplicationLogs({
  assetId,
  index,
  start,
  end,
  sort=initialSortApplication,
  pagination,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'id',
        'created_time',
        'event_type',
        'analyser_name',
        'comments'
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'application_log',
            },
          }
        ],
        filter: [
          {
            range: {
              created_time: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ]
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchApplicationUsers({ 
  assetId,
  index
}) {
  const query = {
    _source: {
      includes: [
        'user_id',
        'role_name',
        'user_name'
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'application_users',
            },
          }
        ],
        filter: []
      },
    },
  };
  return fetchElasticQuery(index, query);
}

const initialSortMaintenance = {
  'created_time': 'asc',
};
export async function fetchMaintenanceEvents({
  assetId,
  index,
  start,
  end,
  sort = initialSortMaintenance.created_time,
  pagination,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'id',
        'created_time',
        'analyser_name',
        'description',
        'alarm_state'
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'maintenance_events',
            },
          }
        ],
        filter: [
          {
            range: {
              created_time: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ]
      },
    },
  };
  return fetchElasticQuery(index, query);
}

const initialSortPerformance = {
  '@timestamp': 'desc',
};
export async function fetchPerformance({
  assetId,
  index,
  start,
  end,
  pagination,
  sort = initialSortPerformance,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'analyser_name',
        'analyser_type',
        'validation_type',
        'availability_rate',
        'breakdown_rate',
        'checking_rate',
        'reproducibility_rate',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'performance',
            },
          }
        ],
        filter: [
          {
            range: {
              '@timestamp': {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ]
      },
    },
  };
  return fetchElasticQuery(index, query);
}

const initialSortValidation = {
  'end_time': 'desc',
};
export async function fetchValidationLogs({
  assetId,
  index,
  start,
  end,
  sort=initialSortValidation,
  pagination,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'id',
        'end_time',
        'result',
        'analyser_name',
        'system_comments',
        'user_comments',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'validation_events',
            },
          }
        ],
        filter: [
          {
            range: {
              end_time: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ]
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchHeartbeatChartData({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            range: {
              '@timestamp': {
                gte: 'now-30d',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
    aggs: {
      state_over_time: {
        date_histogram: {
          field: '@timestamp',
          calendar_interval: '1d',
        },
        aggs: {
          state_aggregation: {
            terms: {
              field: 'state.keyword',
            },
          },
          up_percentage: {
            bucket_script: {
              buckets_path: {
                up: "state_aggregation['up']>_count",
                down: "state_aggregation['down']>_count",
              },
              gap_policy: 'insert_zeros',
              script: {
                source:
                  'if (params.down == null && params.up == null) {-1} else if (params.down == null) {1} else if (params.up == null) {0} else {params.up / (params.up + params.down)}',
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAnalyzerManagementApplication(
  assetId: string,
): Promise<any> {
  return Api.get(`/assets/${assetId}/`);
}

export async function fetchAnalyzerManagementApplications(parameters: {
  site: string;
}): Promise<any> {
  parameters['type'] = 'analyzer_management';
  parameters['deleted'] = 'false';
  return Api.get(`/assets/`, { params: parameters });
}

export async function fetchAnalyzerManagementApplicationsById(): Promise<any> {
  return Api.get(`/assets/autocomplete/name/?model_name=AnalyzerManagementApplicationAAIMS`);
}
