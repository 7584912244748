import Api from 'common/services/Api';

const baseUrl = '/center/';
export function fetchCenter() {
  return Api.get(baseUrl);
}

export function updateCenter(body: any) {
  return Api.put(`${baseUrl}`, body);
}
