import Route from 'common/components/Router/Route';
import React, { Suspense, useEffect } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import Layout from '../Layout/Layout';
import Loading from '../Loading';
import { selectedSite } from 'sites/store/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { selectSite } from 'sites/store/slice';

const Sites = React.lazy(() => import('sites/views'));
const Configuration = React.lazy(() => import('configuration/views'));
const Settings = React.lazy(() => import('settings/views'));
const ReleaseNotes = React.lazy(() =>
  import('releaseNotes/views/ReleaseNotes'),
);

const CenterRoutes = () => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const site = useSelector(selectedSite);
  useEffect(() => {
    if (site.id) dispatch(selectSite({}));
  }, [url, site.id, dispatch]);

  return (
    <Layout layout="Center">
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path={'/sites'} component={Sites} />
          <Route path={'/config'} component={Configuration} />
          <Route path={`/settings`} component={Settings} />
          <Route path={`/release-notes`} component={ReleaseNotes} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default CenterRoutes;
