import Api from 'common/services/Api';

export function sso() {
  return Api.get('/settings/sso/');
}

export function navigation() {
  return Api.get('/nav/');
}

export function me() {
  return Api.get('/me/');
}

export function languages() {
  return Api.get('/languages/');
}

export function timezones() {
  return Api.get('/timezones/');
}

export function acceptTerms(payload: boolean) {
  return Api.post('/me/terms/', { agree: payload });
}

export function terms() {
  return Api.get('/center/terms.html/');
}
