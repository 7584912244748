import React, { useEffect, useState } from 'react';
import {
  Page,
  CompactTable,
  useFetch,
  useFilters,
  PageSidebar,
  usePageQuery,
  openNotificationWithIcon,
} from 'mss-component-library';
import columns from './columns';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from 'common/components/Breadcrumbs';
import { selectedSite } from 'sites/store/selectors';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { priorityToString, stateToString } from 'alerts/util/labels';
import Api from 'common/services/Api';
import { Modal, notification } from 'antd';
import RangePicker, { formatRange } from 'common/components/RangePicker';
import { RangeValue } from 'rc-picker/lib/interface';
import moment from 'moment';

const AlertModal = ({ alert, closeModal }: any) => {
  const [t] = useTranslation([
    'automations',
    'common',
    'assetsCompute',
    'assetsField',
  ]);
  return (
    <Modal
      title={alert.name}
      cancelText={t('common:Close')}
      onCancel={closeModal}
      okButtonProps={{ style: { display: 'none' } }}
      visible
    >
      <div>
        {t(
          `automations:The automation (AUTOMATIONNAME) created the alert (ALERTNAME) with the following message`,
          { automationName: alert.automation, alertName: alert.name },
        )}
        {':'}
      </div>
      <pre>{alert.message}</pre>
    </Modal>
  );
};

const filterKeys = ['asset_name', 'state', 'user_name', 'priority'];
const searchKeys = [
  'asset_name',
  'state',
  'user_name',
  'message',
  'priority',
  'title',
];

const defaultValue: RangeValue<moment.Moment> = [
  moment().add(-1, 'day'),
  moment(),
];

const customOptionLabel = (t: TFunction) => (key: string, value: any) => {
  if (key === 'state') return stateToString(t, value);
  if (key === 'priority') return priorityToString(t, value);
  return value;
};

const customHeadingLabel = (t: TFunction) => (key: string) => {
  if (key === 'title') return t('Title');
  if (key === 'message') return t('assetsField:Message');
  if (key === 'asset_name') return t('dashboard:Asset');
  if (key === 'user_name') return t('assetsCompute:User');
  if (key === 'state') return t('assetsCompute:State');
  if (key === 'priority') return t('Priority');
  return key;
};

const PAGE_SIZE = 10;

export default function List() {
  const [alerts, setAlerts] = useState<any[]>([]);
  const [t] = useTranslation([
    'common',
    'assetsCompute',
    'automations',
    'assetsField',
    'dashboard',
  ]);
  const site = useSelector(selectedSite);
  const [range, setRange] = useState(formatRange(defaultValue));
  const [modal, setModal] = useState();
  const [rawAlerts, fetchAlerts] = useFetch<any[]>({
    url: `${process.env.REACT_APP_API_URL}/alerts/?created_at__gt=${range.from}&created_at__lt=${range.to}`,
    options: { headers: { 'X-Site-Id': site.id } },
    defaultValue: [],
    initialFetch: true,
    onSucces: ({ data }) => {
      setAlerts(
        data.map(x => ({
          ...x,
          asset_name: x.asset?.name,
          custodian_name: x.custodian?.name,
          number:
            Object.keys(x.servicenow_data).length > 0
              ? x.servicenow_data.number
              : null,
          sys_id:
            Object.keys(x.servicenow_data).length > 0
              ? x.servicenow_data.sys_id
              : null,
          servicenow_config_name:
            Object.keys(x.servicenow_data).length > 0
              ? x.servicenow_config.name
              : null,
        })),
      );
    },
    onEmpty: () => setAlerts([]),
  });

  const updateAlert = (id: string, state: string) => async () => {
    try {
      await Api.patch(`/alerts/${id}/`, {
        state,
      });
      fetchAlerts();
      openNotificationWithIcon(
        notification.success,
        t('automations:The alert state has been updated'),
        '',
      );
    } catch {
      openNotificationWithIcon(
        notification.error,
        t('automations:Updating the alert failed'),
        '',
      );
    }
  };

  useEffect(() => {
    fetchAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  const isFetching = rawAlerts.loading;
  const dataSource = alerts || [];
  const [availableFilters, toggleFilter, filteredData, search] = useFilters(
    dataSource,
    filterKeys,
    searchKeys,
    customOptionLabel(t),
    customHeadingLabel(t),
  );
  const [page, goToPage] = usePageQuery(filteredData.length / PAGE_SIZE);
  return (
    <Page
      breadCrumbs={<Breadcrumbs />}
      padding={false}
      actions={
        <RangePicker
          defaultValue={defaultValue}
          onChange={({ to, from }) => {
            if (to !== range.to || from !== range.from) setRange({ to, from });
          }}
          allowClear={false}
        />
      }
      main={
        <>
          <CompactTable
            bordered={false}
            dataSource={filteredData}
            loading={isFetching}
            columns={columns(t, updateAlert)}
            rowKey={'id'}
            onRow={(record: any) => ({
              onClick: () => setModal(record),
            })}
            pagination={{
              current: page,
              showQuickJumper: true,
              total: filteredData.length,
              showTotal: (total: number, range: any[]) =>
                t('common:(RANGE1)-(RANGE2) of (TOTAL) items', {
                  range1: range[0],
                  range2: range[1],
                  TOTAL: total,
                }),
            }}
            onChange={(pagination: any, filters: any, sorter: any) => {
              if (page !== pagination.current) goToPage(pagination.current);
            }}
          />
          {modal && (
            <AlertModal
              alert={modal}
              closeModal={() => {
                setModal(undefined);
              }}
            />
          )}
        </>
      }
      sidebar={
        <PageSidebar
          availableFilters={availableFilters}
          toggleFilter={toggleFilter}
          search={search}
        />
      }
    />
  );
}
