import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import throttle from 'lodash/throttle';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { loadState, saveState } from './localStorage';
import * as reducers from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const preloadedState = loadState();

let store = null;

export function getStore() {
  return store;
}

export default () => {
  const reducer = combineReducers(reducers);

  store = configureStore({
    reducer,
    preloadedState,
    middleware: [...getDefaultMiddleware(), sagaMiddleware],
  });

  // Save selected site into local storage
  store.subscribe(
    throttle(() => {
      saveState({
        default: {
          common: {
            ...store.getState().default.common,
          },
          sites: {
            ...store.getState().default.sites,
            site: store.getState().default.sites.site,
          },
        },
      });
    }, 1000),
  );

  sagaMiddleware.run(rootSaga);
  return store;
};
